export default {
    path: '/topics',
    name: 'topics',
    component: () => import('../components/topic/Layout'),
    redirect: {name: 'TextTemplatesList'},
    children: [
      {
        name: 'TopicList',
        path: '',
        component: () => import('../views/topics/List'),
        meta: {
          listPage: true
        }
      },
      {
        name: 'TopicCreate',
        path: 'new/:parent?',
        component: () => import('../views/topics/Create'),
        meta: {
          createPage: true
        }
      },
      {
        name: 'TopicUpdate',
        path: ':id/edit',
        component: () => import('../views/topics/Update'),
        meta: {
          updatePage: true
        }
      },
    ],
    meta: {
      admin: true
    }
  };
    