export default {
  path: '/backend_users',
  name: 'backend_users',
  component: () => import('../components/backenduser/Layout'),
  redirect: { name: 'BackendUserList' },
  children: [
    {
      name: 'BackendUserList',
      path: '',
      component: () => import('../views/backenduser/List'),
      meta: {
        listPage: true
      }
    },
    {
      name: 'BackendUserCreate',
      path: 'new',
      component: () => import('../views/backenduser/Create'),
      meta: {
        createPage: true
      }
    },
    {
      name: 'BackendUserUpdate',
      path: ':id/edit',
      component: () => import('../views/backenduser/Update'),
      meta: {
        updatePage: true
      }
    },
    {
      name: 'BackendUserShow',
      path: ':id',
      component: () => import('../views/backenduser/Show'),
      meta: {
        detailPage: true
      }
    }
  ]
};
