import Vue from 'vue';
import { getField, updateField } from 'vuex-map-fields';
import remove from 'lodash/remove';
import omit from 'lodash/omit';
import SubmissionError from '../../error/SubmissionError';

const handleError = (commit, e) => {
  commit(ACTIONS.TOGGLE_LOADING);
  if (e instanceof SubmissionError) {
    commit(ACTIONS.SET_VIOLATIONS, e.errors);
    // eslint-disable-next-line
    commit(ACTIONS.SET_ERROR, e.errors._error);

    return;
  }

  // eslint-disable-next-line
  commit(ACTIONS.SET_ERROR, e.message);
};

const ACTIONS = {
  ADD: 'ADD',
  ADD_TO_CACHE: 'ADD_TO_CACHE',
  ADD_ALL_TO_CACHE: 'ADD_ALL_TO_CACHE',
  MERCURE_DELETED: 'MERCURE_DELETED',
  MERCURE_MESSAGE: 'MERCURE_MESSAGE',
  MERCURE_OPEN: 'MERCURE_OPEN',
  RESET_DATA: 'RESET_DATA',
  RESET_LIST: 'RESET_LIST',
  RESET_CACHE_LIST: 'RESET_CACHE_LIST',
  SET_CREATED: 'SET_CREATED',
  SET_DELETED: 'SET_DELETED',
  SET_ERROR: 'SET_ERROR',
  SET_SELECT_ITEMS: 'SET_SELECT_ITEMS',
  SET_TOTAL_ITEMS: 'SET_TOTAL_ITEMS',
  SET_UPDATED: 'SET_UPDATED',
  SET_VIEW: 'SET_VIEW',
  SET_VIOLATIONS: 'SET_VIOLATIONS',
  SET_HANDLED: 'SET_HANDLED',
  SET_HANDLE_ACTION: 'SET_HANDLE_ACTION',
  TOGGLE_LOADING: 'TOGGLE_LOADING',
};
export default function makeCrudModule({
                                         normalizeRelations = x => x,
                                         resolveRelations = x => x,
                                         service,
                                         relations = [],
                                         clearOnTenantSwitch = false,
                                       } = {}) {
  return {
    actions: {
      clearStorage: {
        root: true,
        handler(namespacedContext) {
          namespacedContext.commit(ACTIONS.RESET_LIST);
          namespacedContext.commit(ACTIONS.RESET_DATA);
          namespacedContext.commit(ACTIONS.RESET_CACHE_LIST);
        }
      },
      clearForTenantSwitch: {
        root: true,
        handler(namespacedContext) {
          if (clearOnTenantSwitch) {
            namespacedContext.commit(ACTIONS.RESET_LIST);
            namespacedContext.commit(ACTIONS.RESET_CACHE_LIST);
            namespacedContext.commit(ACTIONS.RESET_DATA);
          }
        }
      },
      relationRemove: {
        root: true,
        handler({ state }, { iri, relationName, toIri }) {
          console.log("relationRemove")
          if (!relations.includes(relationName) || state.allIds.includes(iri) === false) {
            return;
          }

          // following would return an array of removed items!
          //state.byId[iri][relationName] = remove(state.byId[iri][relationName], (n) => (n === toIri));
          remove(state.byId[iri][relationName], (n) => (n === toIri));
        }
      },
      relationAdd: {
        root: true,
        handler({ state }, { iri, relationName, toIri }) {
          console.log("relationAdd")
          if (!relations.includes(relationName) || state.allIds.includes(iri) === false) {
            return;
          }
          if (state.byId[iri][relationName].includes(toIri)) {
            return;
          }
          state.byId[iri][relationName].push(toIri);
        }
      },
      create: ({ commit }, values) => {
        commit(ACTIONS.SET_VIOLATIONS, {});
        commit(ACTIONS.SET_ERROR, '');
        commit(ACTIONS.TOGGLE_LOADING);
        service
            .create(values)
            .then(response => response.json())
            .then(data => {
              commit(ACTIONS.TOGGLE_LOADING);
              commit(ACTIONS.ADD, data);
              commit(ACTIONS.SET_CREATED, data);
              commit(ACTIONS.SET_TOTAL_ITEMS);
            })
            .catch(e => handleError(commit, e));
      },
      createFormData: ({ commit }, values) => {
        commit(ACTIONS.SET_VIOLATIONS, {});
        commit(ACTIONS.SET_ERROR, '');
        commit(ACTIONS.TOGGLE_LOADING);
        return service
            .createFormData(values)
            .then(response => response.json())
            .then(data => {
              commit(ACTIONS.TOGGLE_LOADING);
              commit(ACTIONS.ADD, data);
              commit(ACTIONS.SET_CREATED, data);
              return data;
            })
            .catch(e => {
              handleError(commit, e)
            });
      },
      del: ({ commit }, item) => {
        commit(ACTIONS.TOGGLE_LOADING);
        service
            .del(item)
            .then(() => {
              commit(ACTIONS.TOGGLE_LOADING);
              commit(ACTIONS.SET_DELETED, item);
              commit(ACTIONS.SET_TOTAL_ITEMS);
            })
            .catch(e => handleError(commit, e));
      },
      fetchAll: ({ commit, state }, params) => {
        if (!service) {
          throw new Error('No service specified!');
        }
        commit(ACTIONS.TOGGLE_LOADING);
        return service
            .findAll({ params })
            .then(response => response.json())
            .then(retrieved => {
              commit(
                  ACTIONS.SET_TOTAL_ITEMS,
                  retrieved['hydra:totalItems']
              );
              commit(ACTIONS.SET_VIEW, retrieved['hydra:view']);

              if (true === state.resetList) {
                commit(ACTIONS.RESET_LIST);
              }

              retrieved['hydra:member'].forEach(item => {
                commit(ACTIONS.ADD, normalizeRelations(item));
              });

              return retrieved['hydra:member'];
            })
            .catch(e => handleError(commit, e))
            .finally(() => commit(ACTIONS.TOGGLE_LOADING))
          ;
      },
      fetchAllIntoCache: ({ commit }, params) => {
        if (!service) {
          throw new Error('No service specified!');
        }
        return service
            .findAll({ params })
            .then(response => response.json())
            .then(retrieved => {
              commit(
                  ACTIONS.SET_TOTAL_ITEMS,
                  retrieved['hydra:totalItems']
              );

              commit(ACTIONS.SET_VIEW, retrieved['hydra:view']);

              // use all to cache to prevent iteration on big results
              // caution. this overwrites the state!
              commit(ACTIONS.ADD_ALL_TO_CACHE, retrieved['hydra:member']);

              /*retrieved['hydra:member'].forEach(item => {
                commit(ACTIONS.ADD_TO_CACHE, normalizeRelations(item));
              });*/

              return retrieved['hydra:member'];
            })
            .catch(e => handleError(commit, e));
      },
      fetchSelectItems: ({ commit }, { params = { properties: ['@id', 'name'], pagination: false } } = {}) => {
        commit(ACTIONS.TOGGLE_LOADING);
        if (!service) {
          throw new Error('No service specified!');
        }
        service
            .findAll({ params })
            .then(response => response.json())
            .then(retrieved => {
              commit(
                  ACTIONS.SET_SELECT_ITEMS,
                  retrieved['hydra:member']
              );
            })
            .catch(e => handleError(commit, e));
      },
      load: ({ commit }, id) => {
        if (!service) {
          throw new Error('No service specified!');
        }

        commit(ACTIONS.TOGGLE_LOADING);
        return service
            .find(id)
            .then(response => response.json())
            .then(item => {
              commit(ACTIONS.TOGGLE_LOADING);
              commit(ACTIONS.ADD, normalizeRelations(item));

              return normalizeRelations(item);
            })
            .catch(e => handleError(commit, e));
      },
      update: ({ commit }, item) => {
        commit(ACTIONS.SET_VIOLATIONS, {});
        commit(ACTIONS.SET_ERROR, '');
        commit(ACTIONS.TOGGLE_LOADING);
        return service
            .update(item)
            .then(response => response.json())
            .then(data => {
              commit(ACTIONS.TOGGLE_LOADING);
              commit(ACTIONS.SET_UPDATED, data);

              return data;
            })
            .catch(e => handleError(commit, e));
      },
      action: ({ commit }, { item, action, data = {}, dtoResponse = false, params = [], method = 'POST' }) => {
        commit(ACTIONS.SET_VIOLATIONS, {});
        commit(ACTIONS.SET_ERROR, '');
        commit(ACTIONS.TOGGLE_LOADING);
        commit(ACTIONS.SET_HANDLE_ACTION, action)
        return service
            .action(typeof item !== 'undefined' ? item['@id'] : null, action, data, params, method)
            .then(response => response.json())
            .then(data => {
              commit(ACTIONS.TOGGLE_LOADING);
              commit(ACTIONS.SET_HANDLED, { handled: data, dtoResponse });

              return data;
            })
            .catch(e => handleError(commit, e));
      },
      resetData: ({ commit }, options) => {
        commit(ACTIONS.RESET_DATA, options);
      },
      resetAllData: ({ commit }, options) => {
        commit(ACTIONS.RESET_LIST, options);
        commit(ACTIONS.RESET_CACHE_LIST, options);
        commit(ACTIONS.RESET_DATA, options);
      },
    },
    getters: {
      getField,
      find: state => id => {
        return resolveRelations(state.byId[id]);
      },
      list: (state, getters) => {
        return state.allIds.map(id => getters.find(id));
      },
      listCache: (state, getters) => {
        return state.cacheAllIds.map(id => getters.find(id));
      },
    },
    mutations: {
      updateField,
      [ACTIONS.ADD]: (state, item) => {
        Vue.set(state.byId, item['@id'], item);
        if (false === state.allIds.includes(item['@id'])) {
          state.allIds.push(item['@id']);
        }
        if (false === state.cacheAllIds.includes(item['@id'])) {
          state.cacheAllIds.push(item['@id']);
        }
      },
      [ACTIONS.ADD_TO_CACHE]: (state, item) => {
        Vue.set(state.byId, item['@id'], item);
        if (false === state.cacheAllIds.includes(item['@id'])) {
          state.cacheAllIds.push(item['@id']);
        }
      },
      [ACTIONS.ADD_ALL_TO_CACHE]: (state, items) => {
        Object.assign(state, {
          byId: Object.assign({}, ...items.map(item => ({ [item['@id']]: item }))),
          cacheAllIds: items.map((item) => item['@id'])
        });
      },
      [ACTIONS.RESET_LIST]: state => {
        Object.assign(state, {
          allIds: [],
          resetList: false
        });
      },
      [ACTIONS.RESET_CACHE_LIST]: state => {
        Object.assign(state, {
          cacheAllIds: [],
          byId: {},
          resetCacheList: false
        });
      },
      [ACTIONS.RESET_DATA]: state => {
        Object.assign(state, {
          isLoading: false,
        });
      },
      [ACTIONS.SET_CREATED]: (state, created) => {
        Object.assign(state, { created });
      },
      [ACTIONS.SET_DELETED]: (state, deleted) => {
        if (state.allIds.includes(deleted['@id'])) {
          Object.assign(state, {
            allIds: remove(state.allIds, item => item !== deleted['@id']),
            byId: omit(state.byId, deleted['@id']),
            deleted
          });
        }
        if (state.cacheAllIds.includes(deleted['@id'])) {
          Object.assign(state, {
            cacheAllIds: remove(state.cacheAllIds, item => item !== deleted['@id']),
            deleted
          });
        }
      },
      [ACTIONS.SET_ERROR]: (state, error) => {
        Object.assign(state, { error, isLoading: false });
      },
      [ACTIONS.SET_SELECT_ITEMS]: (state, selectItems) => {
        Object.assign(state, {
          error: '',
          isLoading: false,
          selectItems
        });
      },
      [ACTIONS.SET_TOTAL_ITEMS]: (state, totalItems = null) => {
        Object.assign(state, { totalItems: ((totalItems !== null) ? totalItems : state.cacheAllIds.length) });
      },
      [ACTIONS.SET_UPDATED]: (state, updated) => {
        state.byId[updated['@id']] = updated;
        Object.assign(state, {
          updated
        });
      },
      [ACTIONS.SET_HANDLED]: (state, { handled, dtoResponse }) => {
        if (!dtoResponse) {
          state.byId[handled['@id']] = handled;
        }
        Object.assign(state, { handled });
      },
      [ACTIONS.SET_HANDLE_ACTION]: (state, action) => {
        state.handleAction = action;
      },
      [ACTIONS.SET_VIEW]: (state, view) => {
        Object.assign(state, { view });
      },
      [ACTIONS.SET_VIOLATIONS]: (state, violations) => {
        Object.assign(state, { violations });
      },
      [ACTIONS.TOGGLE_LOADING]: state => {
        Object.assign(state, { error: '', isLoading: !state.isLoading });
      }
    },
    namespaced: true,
    state: {
      allIds: [],
      byId: {},
      cacheAllIds: [],
      created: null,
      deleted: null,
      updated: null,
      handled: null,
      handleAction: null,
      error: '',
      isLoading: false,
      resetList: false,
      resetCacheList: false,
      selectItems: null,
      totalItems: 0,
      view: null,
      violations: null
    }
  };
}
