export default {
  path: '/addresses',
  name: 'addresses',
  component: () => import('../components/address/Layout'),
  children: [
    {
      name: 'AddressUpdate',
      path: ':id/edit',
      component: () => import('../views/address/Update'),
      meta: {
        updatePage: true
      }
    },
    {
      name: 'AddressShow',
      path: ':id',
      component: () => import('../views/address/Show'),
      meta: {
        detailPage: true
      }
    }
  ]
};
