import get from 'lodash/get';
import has from 'lodash/has';
import unset from 'lodash/unset';
import mapValues from 'lodash/mapValues';
import isObject from 'lodash/isObject';

export function normalize(data, related = null) {
  if (has(data, 'hydra:member')) {
    // Normalize items in collections
    data['hydra:member'] = data['hydra:member'].map(item => normalize(item));
    return data;
  }
  ['@id', '@type', '@context'].forEach(val => {
    if (has(data, val)) {
      unset(data, val);
    }
  });
  return mapValues(data, function (value, key) {
    if (related && related[key] && !related[key].normalizeToId) {
      return normalize(value);
    }
    if (Array.isArray(value)) {
      return value.map(v => get(v, '@id', v));
    }
    if (isObject(value) && Object.prototype.hasOwnProperty.call(value, '@editedObject')) {
      delete value['@editedObject'];
      return value;
    }
    return get(value, '@id', value);
  });
}
