import { makeService } from './api';


const relatedObjects = {
  address: {
    normalizeToId: false
  },
}
const service = makeService('venues', relatedObjects);

service.beforeCreate = function (payload) {
  payload.address.type = 'default';
  return payload;
};

export default service;