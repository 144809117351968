import address from './address';
import addon from './addon';
import backendGroupRoutes from './backendgroup';
import backendUserRoutes from './backenduser';
import creditRoutes from './credit'
import customerRoutes from './customer'
import invoiceRoutes from './invoice'
import issueRoutes from './issue';
import menu from './menu';
import newsletterSubscriptionRoutes from './newsletterSubscription'
import offerRoutes from './offer'
import page from './page';
import pages from './pages';
import profile from './profile';
import seminar from './seminar';
import seminarPackages from './seminarPackage';
import speakerRoutes from './speaker';
import store from '../store';
import tenant from './tenant';
import tenantGroupRoutes from './tenantGroup';
import textTemplates from './textTemplates';
import topicRoutes from './topics'
import venue from './venue';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    address,
    addon,
    backendGroupRoutes,
    backendUserRoutes,
    creditRoutes,
    customerRoutes,
    invoiceRoutes,
    issueRoutes,
    menu,
    newsletterSubscriptionRoutes,
    offerRoutes,
    page,
    pages,
    profile,
    seminar,
    seminarPackages,
    speakerRoutes,
    tenant,
    tenantGroupRoutes,
    textTemplates,
    topicRoutes,
    venue,
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.guest)) {
    if (store.state.authentication.loggedIn) {
      next();
      // Todo: what todo?
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.admin) && !to.matched.some(record => record.meta.noAccessToLogin)) {
    if (store.getters['authentication/hasRole']('ROLE_ADMIN')) {
      next();
    } else {
      next({
        path: '/no-access',
      });
    }
  } else {
    if (store.state.authentication.loggedIn) {
      next();
    } else {
      // go to login path if not logged in
      next({
        path: '/login',
      });
    }
  }
});

export default router;