export default {
    path: '/invoices',
    name: 'invoices',
    component: () => import('../components/invoice/Layout'),
    redirect: { name: 'InvoiceList' },
    children: [
      {
        name: 'InvoiceList',
        path: '',
        component: () => import('../views/invoice/List'),
        meta: {
          listPage: true,
          type: 'invoice'
        }
      },
      {
        name: 'InvoiceCreate',
        path: 'new',
        component: () => import('../views/invoice/Create'),
        meta: {
          createPage: true,
          type: 'invoice'
        }
      },
      {
        name: 'InvoiceUpdate',
        path: ':id/edit',
        component: () => import('../views/invoice/Update'),
        meta: {
          updatePage: true,
          type: 'invoice'
        }
      },
      {
        name: 'InvoiceShow',
        path: ':id/show',
        component: () => import('../views/invoice/Show'),
        meta: {
          updatePage: true,
          type: 'invoice'
        }
      },
    ],
    meta: {
      admin: true
    }
  };
