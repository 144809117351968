export default {
  path: '/credits',
  name: 'credits',
  component: () => import('../components/invoice/Layout'),
  redirect: { name: 'CreditList' },
  children: [
    {
      name: 'CreditList',
      path: '',
      component: () => import('../views/invoice/List'),
      meta: {
        listPage: true,
        type: 'credit'
      }
    },
    {
      name: 'CreditCreate',
      path: 'new',
      component: () => import('../views/invoice/Create'),
      meta: {
        createPage: true,
        type: 'credit'
      }
    },
    {
      name: 'CreditUpdate',
      path: ':id/edit',
      component: () => import('../views/invoice/Update'),
      meta: {
        updatePage: true,
        type: 'credit'
      }
    },
    {
      name: 'CreditShow',
      path: ':id/show',
      component: () => import('../views/invoice/Show'),
      meta: {
        updatePage: true,
        type: 'invoice'
      }
    },
  ]
}
