import { makeService } from './api';


const service = makeService('issue_updates');

service.beforeCreate = function (payload) {
    // adjust uploads
    return {
        ...payload,
        //attachments: payload.attachments.map((attachment) => attachment.iri)
    }
}

service.beforeUpdate = function (payload) {
  return payload;
    /*return {
        attachments: payload.attachments.map((attachment) => attachment.iri ? attachment.iri : attachment['@id']),
        description: payload.description,
    }*/
}

export default service;