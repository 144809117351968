<template>
    <v-snackbar
            :color="color"
            :multi-line="true"
            :timeout="timeout"
            right
            top
            v-model="show"
    >
        {{ text }}
        <template v-if="subText">
            <p>{{ subText }}</p>
        </template>
        <v-btn @click.native="close" dark text>{{ $t('Close') }}</v-btn>
    </v-snackbar>
</template>

<script>
  import { mapFields } from 'vuex-map-fields';

  export default {
    computed: {
      ...mapFields('notifications', ['color', 'show', 'subText', 'text', 'timeout'])
    },

    methods: {
      close () {
        this.show = false;
      }
    }
  };
</script>
