export default {
  path: '/profiles',
  name: 'profiles',
  component: () => import('../components/profile/Layout'),
  children: [
    {
      name: 'ProfileCreate',
      path: 'new',
      component: () => import('../views/profile/Create'),
      meta: {
        createPage: true
      }
    },
    {
      name: 'ProfileUpdate',
      path: ':id/edit',
      component: () => import('../views/profile/Update'),
      meta: {
        updatePage: true
      }
    },
    {
      name: 'ProfileShow',
      path: ':id',
      component: () => import('../views/profile/Show'),
      meta: {
        detailPage: true
      }
    }
  ]
};
