import { makeService } from './api';

const relatedObjects = {
    address: {
      normalizeToId: false
    },
    logo: {
      normalizeToId: true
    },
    letterPaperFirst: {
      normalizeToId: true
    },
    letterPaperFurther: {
      normalizeToId: true
    }
}

const service = makeService('tenant_company_settings', relatedObjects);

service.beforeCreate = function (payload) {
  typeof payload.letterPaperFirst === 'object' && delete payload.letterPaperFirst
  typeof payload.letterPaperFurther === 'object' && delete payload.letterPaperFurther
  return payload
}

export default service;