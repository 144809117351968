import { makeService } from './api';

const service = makeService('menu_entries');

const clearUnusedTarget = function (payload) {
    if (payload.type === 0) {
        delete payload.targetUrl;
    } else {
        delete payload.targetPage;
    }
    return payload;
}
service.beforeUpdate = clearUnusedTarget;
service.beforeCreate = clearUnusedTarget;


export default service;