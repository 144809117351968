import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import de from 'vuetify/lib/locale/de';

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        egaBlue: '#327ca1',
        primary: '#327ca1',
        mainAccent: '#4a4a49',
      },
    },
  },
  icons: {
    iconfont: 'mdi'
  },
  lang: {
    locales: { de },
    current: 'de'
  },
};

export default new Vuetify(opts);