export default {
  path: '/addons',
  name: 'addons',
  component: () => import('../components/addon/Layout.vue'),
  redirect: { name: 'AddonList' },
  children: [
    {
      name: 'AddonList',
      path: '',
      component: () => import('../views/addon/List.vue'),
      meta: {
        listPage: true
      }
    },
    {
      name: 'AddonCreate',
      path: 'new/:parent?',
      component: () => import('../views/addon/Create.vue'),
      meta: {
        createPage: true
      }
    },
    {
      name: 'AddonUpdate',
      path: ':id/edit',
      component: () => import('../views/addon/Update.vue'),
      meta: {
        updatePage: true
      }
    }
  ],
  meta: {
    admin: true
  }
};
