export default {
  path: '/issues',
  name: 'issues',
  component: () => import('../components/issue/Layout'),
  redirect: {name: 'IssueList'},
  children: [
    {
      name: 'IssueList',
      path: '',
      component: () => import('../views/issue/List'),
      meta: {
        listPage: true
      }
    },
    {
      name: 'IssueCreate',
      path: 'new',
      component: () => import('../views/issue/Create'),
      meta: {
        createPage: true
      }
    },
    {
      name: 'IssueUpdate',
      path: ':id/edit',
      component: () => import('../views/issue/Update'),
      meta: {
        updatePage: true
      }
    },
    {
      name: 'IssueShow',
      path: ':id',
      component: () => import('../views/issue/Show'),
      meta: {
        detailPage: true
      }
    }
  ],
  meta: {
    admin: true
  }
};
  