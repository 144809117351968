import { makeService } from './api';

const service = makeService('issues');

service.beforeUpdate = function (payload) {
    // adjust patch format
    /*return {
        assignedTo: payload.assignedTo['@id'] ? payload.assignedTo['@id'] : payload.assignedTo,
        //attachments: payload.attachments.map((attachment) => attachment.iri ? attachment.iri : attachment['@id']),
        description: payload.description,
        end: payload.end,
        priority: payload.priority,
        start: payload.start,
        status: payload.status,
        title: payload.title,
    };*/
  return payload;
};

service.beforeCreate = function (payload) {
    // adjust uploads
    return {
        ...payload,
        attachments: payload.attachments.map((attachment) => attachment.iri)
    }
}

export default service;