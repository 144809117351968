import { makeService } from './api';


const relatedObjects = {
  invoiceNumberRange: {
    normalizeToId: false
  },
  creditNumberRange: {
    normalizeToId: false
  },
  offerNumberRange: {
    normalizeToId: false
  },
  orderConfirmationNumberRange: {
    normalizeToId: false
  },
  customerNumberRange: {
    normalizeToId: false
  },
  seminarPackageNumberRange: {
    normalizeToId: false
  },
  paymentMethods: {
    normalizeToId: false
  },
  dunningLevels: {
    normalizeToId: false
  },
}

const service = makeService('tenant_account_settings', relatedObjects);

service.beforeCreate = function (payload) {

  payload.dunningLevels.map((item, index) => {
    delete payload.dunningLevels[index]['@id']
    delete payload.dunningLevels[index]['@notPersisted']
  })

  payload.paymentMethods.map((item, index) => {
    delete payload.paymentMethods[index]['@id']
    delete payload.paymentMethods[index]['@notPersisted']
  })
  
  return payload;
};

export default service;