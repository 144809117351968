export default {
  path: '/seminar-packages',
  name: 'seminarPackages',
  component: () => import('../components/seminarPackage/Layout.vue'),
  redirect: { name: 'SeminarList' },
  children: [
    {
      name: 'SeminarPackageList',
      path: '',
      component: () => import('../views/seminarPackage/List.vue'),
      meta: {
        listPage: true
      }
    },
    {
      name: 'SeminarPackageCreate',
      path: 'new',
      component: () => import('../views/seminarPackage/Create.vue'),
      meta: {
        createPage: true
      }
    },
    {
      name: 'SeminarPackageUpdate',
      path: ':id/edit',
      component: () => import('../views/seminarPackage/Update.vue'),
      meta: {
        updatePage: true
      }
    }
  ],
  meta: {
    admin: true
  }
};
