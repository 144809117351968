export default {
  path: '/seminars',
  name: 'seminars',
  component: () => import('../components/seminar/Layout'),
  redirect: {name: 'SeminarList'},
  children: [
    {
      name: 'SeminarList',
      path: '',
      component: () => import('../views/seminar/List'),
      meta: {
        listPage: true
      }
    },
    {
      name: 'SeminarShow',
      path: ':id',
      component: () => import('../views/seminar/Show'),
      meta: {
        detailPage: true
      }
    }
  ],
  meta: {
    admin: true
  }
};
