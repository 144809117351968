export default {
  path: '/text_templates',
  name: 'text_templates',
  component: () => import('../components/textTemplate/Layout'),
  redirect: {name: 'TextTemplatesList'},
  children: [
    {
      name: 'TextTemplatesList',
      path: '',
      component: () => import('../views/textTemplates/List'),
      meta: {
        listPage: true
      }
    },
    {
      name: 'TextTemplateCreate',
      path: 'new',
      component: () => import('../views/textTemplates/Create'),
      meta: {
        createPage: true
      }
    },
    {
      name: 'TextTemplateUpdate',
      path: ':id/edit',
      component: () => import('../views/textTemplates/Update'),
      meta: {
        updatePage: true
      }
    },
  ],
  meta: {
    admin: true
  }
};
  