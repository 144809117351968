<template>
  <div>
    <v-breadcrumbs :class="layoutClass" :items="items" divider="/">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :to="{ name: item.name }"
          :disabled="item.disabled"
        >
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: ['layoutClass'],
  data() {
    return {};
  },
  computed: {
    items() {
      const {path, matched} = this.$route;
      const items = [
        {
          text: this.$t('Home'),
          href: '/dashboard'
        }
      ];
      const lastItem = matched[matched.length - 1];
      for (let i = 0, len = matched.length; i < len; i += 1) {
        const route = matched[i];
        if (route.path) {
          let routeName = '';

          if (route.meta.detailPage) {
            routeName = this.$t('Detail');
          } else if (route.meta.createPage) {
            routeName = this.$t('Create');
          } else if (route.meta.updatePage) {
            routeName = this.$t('Update');
          }  else if (route.meta.listPage) {
            routeName = this.$t('Overview');
          } else {
            routeName = this.$t(route.name);
          }

          items.push({
            text: routeName,
            disabled: route.path === path || lastItem.path === route.path,
            href: route.path,
            name: route.name
          });
        }
      }

      return items;
    }
  }
};
</script>
