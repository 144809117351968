export default {
  path: '/tenants',
  name: 'tenants',
  component: () => import('../components/tenant/Layout'),
  children: [
    {
      name: 'TenantCreate',
      path: 'new',
      component: () => import('../views/tenant/Create'),
      meta: {
        createPage: true
      }
    },
    {
      name: 'TenantUpdate',
      path: ':id/edit',
      component: () => import('../views/tenant/Update'),
      meta: {
        updatePage: true
      }
    },
    {
      name: 'TenantShow',
      path: ':id',
      component: () => import('../views/tenant/Show'),
      meta: {
        detailPage: true
      }
    },
    {
      name: 'TenantSeminarSettingCreate',
      path: ':id/tenantSeminarSettings/new',
      component: () => import('../views/tenantSeminarSetting/Create'),
      meta: {
        createPage: true
      }
    },
    {
      name: 'TenantSeminarSettingUpdate',
      path: ':id/edit',
      component: () => import('../views/tenantSeminarSetting/Update'),
      meta: {
        updatePage: true
      }
    },
    {
      name: 'TenantAccountSettingCreate',
      path: ':id/tenantAccountSettings/new',
      component: () => import('../views/tenantAccountSetting/Create'),
      meta: {
        createPage: true
      }
    },
    {
      name: 'TenantAccountSettingUpdate',
      path: ':id/edit',
      component: () => import('../views/tenantAccountSetting/Update'),
      meta: {
        updatePage: true
      }
    },
    {
      name: 'TenantCompanySettingCreate',
      path: ':id/tenantCompanySettings/new',
      component: () => import('../views/tenantCompanySetting/Create'),
      meta: {
        createPage: true
      }
    },
    {
      name: 'TenantCompanySettingUpdate',
      path: ':id/edit',
      component: () => import('../views/tenantCompanySetting/Update'),
      meta: {
        updatePage: true
      }
    }
  ],
  meta: {
    admin: true
  }
};
