import { makeService } from './api';

const service = makeService('participants');

service.beforeCreate = function (payload) {
  // remove null fe user from the payload
  if (!payload.customer) {
    delete payload['customer']
  }
  return {
    ...payload,
  }
}

export default service;