import {mapFields} from 'vuex-map-fields';
import jwtDecode from 'jwt-decode';

export default {
  computed: {
    ...mapFields('authentication', ['token', 'loggedIn', 'userData', 'profileLoaded']),
  },
  methods: {
    getToken() {
      return this.token;
    },
    getPermissions() {
      if (this.token) {
        return jwtDecode(this.token).roles;
      }
      return null;
    },
    getUserIri() {
      if (this.token) {
        return jwtDecode(this.token).iri;
      }
      return null;
    },
    hasRole(role) {
      if (this.getPermissions() === null) {
        return false;
      }
      return this.getPermissions().includes(role);
    }
  }

};
