import { makeService, modifyGroups } from './api';

const relatedObjects = {
  time_slots: {
    byId: {},
    totalItems: 0
  },
  address: {
    normalizeToId: false
  },
}

const service = makeService('speakers', relatedObjects);

service.beforeCreate = function (payload) {
  return modifyGroups(payload);
};
service.beforeUpdate = function (payload) {
  return modifyGroups(payload);
};

export default service;