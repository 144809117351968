import {getField, updateField} from 'vuex-map-fields';
import {userService} from '../../services/user';
import router from '../../router';
import jwtDecode from 'jwt-decode';
import utilFetch from '../../utils/fetch';
import i18n from '../../i18n';
import {ENTRYPOINT} from '@/config/entrypoint';

const ACTIONS = {
  RESET_DATA: 'RESET_DATA',
};

export const authentication = {
  namespaced: true,
  state: {
    loggedIn: false,
    token: null,
    profileLoaded: false,
    userData: {}
  },
  actions: {
    login({dispatch, commit}, {email, password}) {
      commit('loginRequest', {email});
      userService.login(email, password)
        .then(
          token => {
            if (token === null) {
              commit('logout', 'No Backend Access');
              return;
            }
            commit('loginSuccess', token);
            this.dispatch('authentication/userData', {token});
            router.push('/');
          },
          error => {
            commit('loginFailure', error);
            dispatch('notifications/error', i18n.t(error), {root: true});
          }
        );
    },
    logout({commit}) {
      commit('logout');
    },
    clearNowForTenantSwitch({dispatch}) {
      dispatch('clearForTenantSwitch', null, {root: true});
    },
    loginFromStorage({commit}, {token}) {
      commit('loginSuccess', token);
      this.dispatch('authentication/userData', {token});
    },
    userData({commit}, {token}) {
      let decodedToken = jwtDecode(token);
      let profileIri = decodedToken.profile;
      let userIri = decodedToken.iri;

      utilFetch(userIri, {authenticationToken: token}).then(response => response.json())
        .then(userData => {
          commit('userDataSuccess', {userData});
          if (userData.activeTenant !== null) {
            this.dispatch('authentication/resolveTenantData', userData.activeTenant);
          }
        });

      if (profileIri !== null) {
        utilFetch(profileIri, {authenticationToken: token}).then(response => response.json())
          .then(profileData => {
            commit('profileDataSuccess', profileData);
            if (profileData.image) {
              utilFetch(profileData.image, {authenticationToken: token}).then(response => response.json())
                .then(imageData => {
                  commit('profileImageSuccess', ENTRYPOINT + imageData.contentUrl);
                });
            }
          });
      }
    },
    selectTenant({dispatch, commit}, {userIri, tenant, redirect}) {
      utilFetch(`${userIri}/active_tenant`, {
        method: 'PUT',
        body: JSON.stringify({'activeTenant': (tenant !== null ? tenant['@id'] : null)})
      }).then(response => response.json())
        .then(user => {
            let activeTenant = user.activeTenant;
            commit('tenantSelectSuccess', activeTenant);

            this.dispatch('authentication/resolveTenantData', tenant);
            this.dispatch("authentication/clearNowForTenantSwitch");

            if (redirect && router.history.current.name !== 'Dashboard') {
              router.push({name: 'Dashboard'});
            }
          },
          error => {
            dispatch('notifications/error', i18n.t('Cannot set Tenant: ' + error.message), {root: true});
          }
        );
    },
    resolveTenantData({dispatch}, tenant) {
      let seminarSettings = {tenant, type: "tenantSeminarSetting", key: "seminarSettings"};
      let companySettings = {tenant, type: "tenantCompanySetting", key: "companySettings"};
      let accountSettings = {tenant, type: "tenantAccountSetting", key: "accountingSettings"};

      dispatch('fetchTenantData', seminarSettings);
      dispatch('fetchTenantData', companySettings);
      dispatch('fetchTenantData', accountSettings);
    },
    fetchTenantData({dispatch}, typeData) {
      if (typeData.tenant !== null && typeData.tenant[typeData.key]) {
        var tenantData = typeData.tenant[typeData.key];
        if (typeof tenantData === 'object') {
          tenantData = tenantData['@id'];
        }
        dispatch(typeData.type + "/load", tenantData, {root: true});
      } else {
        dispatch(typeData.type + "/resetData", null, {root: true});
      }
    },
    clearStorage: {
      root: true,
      handler(namespacedContext) {
        namespacedContext.commit(ACTIONS.RESET_DATA);
      }
    },
  },
  mutations: {
    updateField,
    loginRequest(state, email) {
      state.loggingIn = true;
      state.token = email;
    },
    loginSuccess(state, token) {
      state.loggingIn = false;
      state.loggedIn = true;
      state.token = token;
    },
    loginFailure(state) {
      state.loggingIn = false;
      state.loggedIn = false;
      state.token = null;
    },
    logout(state) {
      state.loggingIn = false;
      state.loggedIn = false;
      state.token = null;
      state.profileLoaded = false;
      state.userData = {};
    },
    userDataSuccess(state, {userData}) {
      state.userData = Object.assign({}, state.userData, {
        iri: userData['@id'],
        email: userData.email,
        activeTenant: userData.activeTenant,
        type: (userData['@type'] === 'http://schema.org/BackendUser') ? 'backendUser' : 'speaker',

      })
    },
    profileDataSuccess(state, profileData) {
      state.profileLoaded = true;
      state.userData = Object.assign({}, state.userData, {
        profileIri: profileData['@id'],
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        salutation: profileData.salutation,
        title: profileData.title,
        company: profileData.company,
      });
    },
    profileImageSuccess(state, profileImage) {
      state.userData = Object.assign({}, state.userData, {
        profileImage: profileImage,
      });
    },
    profileDataRemove(state) {
      state.profileLoaded = false;
      state.userData.profileIri = null;
      state.userData.firstName = null;
      state.userData.lastName = null;
      state.userData.salutation = null;
      state.userData.title = null;
      state.userData.company = null;
    },
    tenantSelectSuccess(state, tenant) {
      state.userData.activeTenant = tenant;
    },
    [ACTIONS.RESET_DATA]: state => {
      Object.assign(state, {
        profileLoaded: false,
        loggingIn: false,
        loggedIn: false,
        token: null,
        userData: {},
      });
    },
  },
  getters: {
    getPermissions: (state) => {
      if (state.token === null) {
        return [];
      }
      return jwtDecode(state.token).roles;
    },
    hasRole: (state, getters) => (role) => {
      return getters.getPermissions.includes(role);
    },
    getUserIri: (state) => {
      return jwtDecode(state.token).iri;
    },
    getField,
  },
};
