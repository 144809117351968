export default {
  path: '/offers',
  name: 'offers',
  component: () => import('../components/offer/Layout'),
  redirect: { name: 'OfferList' },
  children: [
    {
      name: 'OfferList',
      path: '',
      component: () => import('../views/offer/List'),
      meta: {
        listPage: true
      }
    },
    {
      name: 'OfferCreate',
      path: 'new',
      component: () => import('../views/offer/Create'),
      meta: {
        createPage: true
      }
    },
    {
      name: 'OfferUpdate',
      path: ':id/edit',
      component: () => import('../views/offer/Update'),
      meta: {
        updatePage: true
      }
    },
  ],
  meta: {
    admin: true
  }
};
