export default {
    path: '/pages',
    name: 'pages',
    component: () => import('../components/page/Layout'),
    redirect: { name: 'PageList' },
    children: [
      {
        name: 'PageList',
        path: '',
        component: () => import('../views/page/List'),
        meta: {
          listPage: true
        }
      },
      {
        name: 'PageCreate',
        path: 'new',
        component: () => import('../views/page/Create'),
        meta: {
          createPage: true
        }
      },
      {
        name: 'PageUpdate',
        path: ':id/edit',
        component: () => import('../views/page/Update'),
        meta: {
          updatePage: true
        }
      },
    ],
    meta: {
      admin: true
    }
  };
  