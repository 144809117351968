import { authentication } from './modules/authentication';
import addonService from '../services/addon';
import addressService from '../services/address';
import backendGroupService from '../services/backendgroup';
import backendUserService from '../services/backenduser';
import bankAccountService from '../services/bankAccount';
import contactService from '../services/contact';
import customerService from '../services/customer';
import discountService from '../services/discount';
import documentService from '../services/document';
import dunningLetterService from '../services/dunningLetter';
import dunningLevelService from '../services/dunningLevel';
import expenseService from '../services/expense';
import feeService from '../services/fee';
import frontendGroupService from '../services/frontendGroup';
import invoiceService from '../services/invoice';
import issueService from '../services/issue';
import issueUpdateService from '../services/issueUpdate';
import makeCrudModule from './modules/crud';
import meetingService from '../services/meeting';
import menuEntryService from '../services/menuEntry';
import menuService from '../services/menu';
import newsletterSubscriptionService from '../services/newsletterSubscription';
import noteService from '../services/note';
import notifications from './modules/notifications';
import offerService from '../services/offer';
import pageService from '../services/page';
import participantService from '../services/participant';
import paymentService from '../services/payment';
import paymentMethodService from '../services/paymentMethod';
import positionService from '../services/position';
import profileService from '../services/profile';
import seminarService from '../services/seminar';
import seminarPackageService from '../services/seminarPackage';
import speakerService from '../services/speaker';
import tenantAccountSettingService from '../services/tenantAccountSetting';
import tenantCompanySettingService from '../services/tenantCompanySetting';
import tenantGroupService from '../services/tenantGroup';
import tenantSeminarSettingService from '../services/tenantSeminarSetting';
import tenantService from '../services/tenant';
import tenantUserService from '../services/tenantUser';
import textTemplateService from '../services/textTemplate';
import timeSlotService from '../services/timeslot';
import topicService from '../services/topic';
import venueService from '../services/venue';
import attendanceListTemplateService from '../services/attendanceListTemplate';
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

const vuexLocalSeminar = new VuexPersistence({
  key: 'esa-seminar',
  modules: [
    'seminar',
    'seminarPackage',
    'timeSlot',
    'expense',
    'note',
    'participant',
    'addon',
    'meeting',
    'attendanceListTemplate'
  ],
  storage: window.localStorage
});
const vuexLocalTenant = new VuexPersistence({
  key: 'esa-tenant',
  modules: [
    'tenantUser',
    'tenantGroup',
    'tenant',
    'tenantAccountSetting',
    'tenantCompanySetting',
    'tenantSeminarSetting'
  ],
  storage: window.localStorage
});
const vuexLocal = new VuexPersistence({
  key: 'esa-default',
  modules: [
    'backendGroup',
    'backendUser',
    'frontendGroup',
    'customer',
    'profile',
    'address',
    'contact',
    'document',
    'notifications',
    'authentication',
    'topic'
  ],
  storage: window.localStorage
});
const vuexAccounting = new VuexPersistence({
  key: 'esa-accounting',
  modules: [
    'paymentMethod',
    'dunningLevel',
    'fee',
    'discount',
    'offer',
    'position',
    'invoice',
    'dunningLetter',
    'bankAccount',
    'payment'
  ],
  storage: window.localStorage
});
const vuexContent = new VuexPersistence({
  key: 'esa-content',
  modules: ['textTemplate', 'newsletterSubscription', 'issue', 'issueUpdate', 'menu', 'page', 'menuEntry'],
  storage: window.localStorage
});
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    notifications,
    authentication,
    backendGroup: makeCrudModule({
      service: backendGroupService
    }),
    backendUser: makeCrudModule({
      service: backendUserService,
      relations: ['tenantUsers', 'groups']
    }),
    speaker: makeCrudModule({
      service: speakerService,
      relations: ['fees', 'tenantUsers', 'groups']
    }),
    timeslot: makeCrudModule({
      service: timeSlotService
    }),
    profile: makeCrudModule({
      service: profileService
    }),
    tenant: makeCrudModule({
      service: tenantService
    }),
    tenantAccountSetting: makeCrudModule({
      service: tenantAccountSettingService
    }),
    tenantCompanySetting: makeCrudModule({
      service: tenantCompanySettingService
    }),
    tenantSeminarSetting: makeCrudModule({
      service: tenantSeminarSettingService
    }),
    document: makeCrudModule({
      service: documentService
    }),
    address: makeCrudModule({
      service: addressService
    }),
    paymentMethod: makeCrudModule({
      service: paymentMethodService
    }),
    contact: makeCrudModule({
      service: contactService
    }),
    dunningLevel: makeCrudModule({
      service: dunningLevelService
    }),
    fee: makeCrudModule({
      service: feeService
    }),
    discount: makeCrudModule({
      service: discountService
    }),
    tenantUser: makeCrudModule({
      service: tenantUserService
    }),
    tenantGroup: makeCrudModule({
      service: tenantGroupService
    }),
    textTemplate: makeCrudModule({
      service: textTemplateService
    }),
    seminar: makeCrudModule({
      service: seminarService,
      relations: ['participants', 'expenses', 'notes'],
      clearOnTenantSwitch: true
    }),
    seminarPackage: makeCrudModule({
      service: seminarPackageService,
      clearOnTenantSwitch: true
    }),
    issue: makeCrudModule({
      service: issueService,
      relations: ['attachments']
    }),
    issueUpdate: makeCrudModule({
      service: issueUpdateService,
      relations: ['attachments']
    }),
    topic: makeCrudModule({
      service: topicService,
      clearOnTenantSwitch: true
    }),
    note: makeCrudModule({
      service: noteService,
      clearOnTenantSwitch: true
    }),
    expense: makeCrudModule({
      service: expenseService,
      clearOnTenantSwitch: true
    }),
    participant: makeCrudModule({
      service: participantService,
      clearOnTenantSwitch: true
    }),
    frontendGroup: makeCrudModule({
      service: frontendGroupService
    }),
    customer: makeCrudModule({
      service: customerService,
      clearOnTenantSwitch: true
    }),
    meeting: makeCrudModule({
      service: meetingService,
      clearOnTenantSwitch: true
    }),
    offer: makeCrudModule({
      service: offerService,
      clearOnTenantSwitch: true
    }),
    position: makeCrudModule({
      service: positionService,
      clearOnTenantSwitch: true
    }),
    invoice: makeCrudModule({
      service: invoiceService,
      clearOnTenantSwitch: true
    }),
    menu: makeCrudModule({
      service: menuService
    }),
    page: makeCrudModule({
      service: pageService
    }),
    menuEntry: makeCrudModule({
      service: menuEntryService
    }),
    venue: makeCrudModule({
      service: venueService
    }),
    dunningLetter: makeCrudModule({
      service: dunningLetterService
    }),
    bankAccount: makeCrudModule({
      service: bankAccountService
    }),
    payment: makeCrudModule({
      service: paymentService
    }),
    newsletterSubscription: makeCrudModule({
      service: newsletterSubscriptionService
    }),
    attendanceListTemplate: makeCrudModule({
      service: attendanceListTemplateService
    }),
    addon: makeCrudModule({
      service: addonService
    })
  },
  actions: {
    logout({ dispatch }) {
      dispatch('clearStorage');
    },
    removeRelation({ dispatch }, data) {
      dispatch('relationRemove', data);
    },
    addRelation({ dispatch }, data) {
      dispatch('relationAdd', data);
    }
  },
  plugins: [
    vuexLocal.plugin,
    vuexLocalSeminar.plugin,
    vuexLocalTenant.plugin,
    vuexAccounting.plugin,
    vuexContent.plugin
  ]
});
/*
var _lsTotal = 0,
  _xLen,
  _x;
for (_x in window.localStorage) {
  _xLen = ((window.localStorage[_x].length || 0) + (_x.length || 0)) * 2;
  _lsTotal += _xLen;
  console.log(_x.substr(0, 50) + ' = ' + (_xLen / 1024).toFixed(2) + ' KB');
}
console.log('Total = ' + (_lsTotal / 1024).toFixed(2) + ' KB');
*/
export default store;
