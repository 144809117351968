export default {
  path: '/customers',
  name: 'customers',
  component: () => import('../components/customer/Layout'),
  redirect: { name: 'CustomerList' },
  children: [
    {
      name: 'CustomerList',
      path: '',
      component: () => import('../views/customer/List'),
      meta: {
        listPage: true
      }
    },
    {
      name: 'CustomerCreate',
      path: 'new',
      component: () => import('../views/customer/Create'),
      meta: {
        createPage: true
      }
    },
    {
      name: 'CustomerUpdate',
      path: ':id/edit',
      component: () => import('../views/customer/Update'),
      meta: {
        updatePage: true
      }
    },
    {
      name: 'CustomerShow',
      path: ':id',
      component: () => import('../views/customer/Show'),
      meta: {
        detailPage: true
      }
    }
  ],
  meta: {
    admin: true
  }
};
