import fetch from '../utils/fetch';

export function modifyGroups(payload) {
  var groups = [];
  if (!("groups" in payload)) {
    return payload;
  }
  payload.groups.forEach(element => {
    if (typeof element === 'object' && element !== null) {
      groups.push(element['@id']);
    } else if (element !== null) {
      groups.push(element);
    }
  });
  payload.groups = groups;
  return payload;
}

export function modifyTimeSlot(payload, update = false) {
  var timeSlot = {
    start: null,
    end: null,
  };
  timeSlot.start = payload.timeSlot.start;
  timeSlot.end = payload.timeSlot.end;

  if (update) {
    timeSlot['@id'] = payload.timeSlot['@id'];
    timeSlot['@editedObject'] = true;
  }
  payload.timeSlot = timeSlot;
  return payload;
}

export function makeService(endpoint, related = null) {
  return {
    find(id) {
      return fetch(`${id}`);
    },
    findAll(params) {
      return fetch(endpoint, params);
    },
    create(payload) {
      return fetch(endpoint, {method: 'POST', body: JSON.stringify(this.beforeCreate(payload))});
    },
    createFormData(payload) {
      this.beforeCreate(payload);
      return fetch(endpoint, {method: 'POST', body: payload});
    },
    beforeCreate(payload) {
      return payload;
    },
    del(item) {
      return fetch(item['@id'], {method: 'DELETE'});
    },
    update(payload) {
      return fetch(payload['@id'], {
        method: 'PUT',
        body: JSON.stringify(this.beforeUpdate(payload)),
        related
      });
    },
    action(id, action, data = {}, params = [], method = 'POST') {
      // support get params for actions
      let searchParams = new URLSearchParams();
      params.map((param) => searchParams.set(param.key, param.value))
      let append = searchParams.toString() !== '' ? '?' + searchParams.toString() : '';

      let fetchUrl = id != null ? `${id}/${action}${append}` : `${action}${append}`;

      return fetch(fetchUrl, {method: method, body: (data === null ? null : JSON.stringify(data))});
    },
    beforeUpdate(payload) {
      return payload;
    },
    getRelatedObjects() {
      return related;
    }
  };
}
