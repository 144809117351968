<template>
    <section>
        <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" md="4">
                    <v-card class="mx-auto" :loading="isLoading">
                        <v-toolbar color="primary" dark flat>
                            <v-toolbar-title>{{$t('AccountConfirm')}}</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <template v-if="isLoading">
                                {{$t('AccountConfirmText')}}
                            </template>
                            <template v-if="!isLoading && !isError">
                                {{$t('AccountConfirmedText')}}<br/>
                                {{$t('LoginRedirectInfo')}}
                            </template>
                            <template v-if="!isLoading && isError">
                                {{$t('AccountConfirmError')}}
                            </template>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
  import fetch from '../../utils/fetch';

  export default {
    name: 'AccountConfirm',
    data () {
      return {
        isLoading: true,
        isError: false,
      };
    },
    created () {
      fetch(`/confirm/${this.$route.meta.type}/${decodeURIComponent(this.$route.params.token)}`)
        .then(response => this.confirmSuccess(response.status))
        .catch(error => this.confirmError(error));
    },
    methods: {
      confirmSuccess (statusCode) {
        this.isLoading = false;
        if (statusCode !== 200) {
          this.isError = true;
        }
        setTimeout(() => this.$router.push({ name: 'Login' }), 1000);
      },
      confirmError () {
        this.isError = true;
        this.isLoading = false;
      }
    }
  };
</script>
