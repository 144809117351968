import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";
import AuthenticationMixin from "@/mixins/AuthenticationMixin";

export default {
  mixins: [AuthenticationMixin],
  data() {
    return {
      loadingTenantObjects: [],
      loadingTenants: [],

      loadingAccountSettings: [],
      loadingSeminarSettings: [],
      loadingCompanySettings: [],
    };
  },
  watch: {},
  computed: {
    ...mapFields("tenant", {
      tenantIds: "allIds",
      tenantIdsCached: "cacheAllIds",
      deletedTenantItem: 'deleted',
    }),
    ...mapGetters("tenant", {
      tenantItems: 'list',
      findTenant: 'find'
    }),
    ...mapFields('tenantCompanySetting', {
      companySettingsById: 'byId',
      companySettingIds: 'allIds',
      companySettingIdsCached: "cacheAllIds",
    }),
    ...mapFields('tenantSeminarSetting', {
      seminarSettingsById: 'byId',
      seminarSettingIds: 'allIds',
      seminarSettingIdsCached: "cacheAllIds",
    }),
    ...mapFields('tenantAccountSetting', {
      accountSettingsById: 'byId',
      accountSettingIds: 'allIds',
      accountSettingIdsCached: "cacheAllIds",
    }),
    ...mapGetters('tenantCompanySetting', {
      companySettingItems: 'list',
      findCompanySetting: 'find'
    }),
    ...mapGetters('tenantSeminarSetting', {
      seminarSettingItems: 'list',
      findSeminarSetting: 'find'
    }),
    ...mapGetters('tenantAccountSetting', {
      accountSettingItems: 'list',
      findAccountSetting: 'find'
    }),
    activeTenant() {
      if (this.userData == null || !this.userData.activeTenant) {
        return null;
      }
      return this.resolveTenant(this.userData.activeTenant['@id']);
    },
    currentSeminarSettings() {
      return this.getSeminarSettings();
    },
    currentAccountSettings() {
      return this.getAccountSettings();
    },

    isInhouse() {
      return !this.currentSeminarSettings.publicBookable && !this.currentSeminarSettings.publicVisibility && !this.currentAccountSettings.customerLoginEnabled;
    },
    isOnline() {
      return this.currentSeminarSettings.publicBookable && this.currentSeminarSettings.publicVisibility && this.currentAccountSettings.customerLoginEnabled;
    }
  },
  methods: {
    ...mapActions("tenant", {
      retrieveTenant: "load",
      deleteTenantItem: 'del',
      updateTenant: 'update',
    }),
    ...mapActions('tenantSeminarSetting', {
      retrieveTenantSeminarSetting: 'load',
    }),
    ...mapActions('tenantAccountSetting', {
      retrieveTenantAccountSetting: 'load',
    }),
    ...mapActions('tenantCompanySetting', {
      retrieveTenantCompanySetting: 'load',
    }),
    isSeminarSettingLoaded(itemId) {
      return this.seminarSettingIdsCached.includes(itemId);
    },
    resolveSeminarSetting(itemId) {
      if (!this.isSeminarSettingLoaded(itemId) && !this.loadingSeminarSettings.includes(itemId)) {
        this.loadingSeminarSettings.push(itemId);
        this.retrieveTenantSeminarSetting(decodeURIComponent(itemId));
      }
      return this.findSeminarSetting(itemId);
    },
    isAccountSettingLoaded(itemId) {
      return this.accountSettingIdsCached.includes(itemId);
    },
    resolveAccountSetting(itemId) {
      if (!this.isAccountSettingLoaded(itemId) && !this.loadingAccountSettings.includes(itemId)) {
        this.loadingAccountSettings.push(itemId);
        this.retrieveTenantAccountSetting(decodeURIComponent(itemId));
      }
      return this.findAccountSetting(itemId);
    },
    isCompanySettingLoaded(itemId) {
      return this.companySettingIdsCached.includes(itemId);
    },
    resolveCompanySetting(itemId) {
      if (!this.isCompanySettingLoaded(itemId) && !this.loadingCompanySettings.includes(itemId)) {
        this.loadingCompanySettings.push(itemId);
        this.retrieveTenantCompanySetting(decodeURIComponent(itemId));
      }
      return this.findCompanySetting(itemId);
    },
    getSeminarSettings(tenant = null) {
      if (tenant === null) {
        if ((tenant = this.activeTenant) === null || typeof tenant === 'undefined') {
          return null;
        }
      }
      return this.resolveSeminarSetting(tenant.seminarSettings);
    },
    getAccountSettings(tenant = null) {
      if (tenant === null) {
        if ((tenant = this.activeTenant) === null) {
          return null;
        }
      }
      return this.resolveAccountSetting(tenant.accountingSettings);
    },
    getCompanySettings(tenant = null) {
      if (tenant === null) {
        if ((tenant = this.activeTenant) === null) {
          return null;
        }
      }
      return this.resolveCompanySetting(tenant.companySettings);
    },
    resolveTenant(itemId) {
      if (!this.isTenantLoaded(itemId) && !this.loadingTenants.includes(itemId)) {
        this.loadingTenants.push(itemId);
        this.retrieveTenant(decodeURIComponent(itemId));
      }
      return this.findTenant(itemId);
    },
    resolveTenants(itemIds) {
      if (typeof itemIds === "undefined") {
        return [];
      }
      return itemIds.map((itemId) => {
        return this.resolveTenant(itemId);
      }).filter(e => e);
    },
    isTenantLoaded(itemId) {
      return this.tenantIdsCached.includes(itemId);
    },
    getTenantItem(itemId, type) {
      if (itemId !== null) {
        let tenant = this.resolveTenant(itemId);
        if (tenant) {
          return tenant[type];
        }
      }
      return '';
    },
  }
};
