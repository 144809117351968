export default {
  path: '/venues',
  name: 'venues',
  component: () => import('../components/venue/Layout'),
  redirect: { name: 'VenueList' },
  children: [
    {
      name: 'VenueList',
      path: '',
      component: () => import('../views/venue/List'),
      meta: {
        listPage: true
      }
    },
    {
      name: 'VenueCreate',
      path: 'new',
      component: () => import('../views/venue/Create'),
      meta: {
        createPage: true
      }
    },
    {
      name: 'VenueUpdate',
      path: ':id/edit',
      component: () => import('../views/venue/Update'),
      meta: {
        updatePage: true
      }
    },
  ],
  meta: {
    admin: true
  }
};
