import { makeService } from './api';


const relatedObjects = {
  numberRange: {
    normalizeToId: false
  },
}

const service = makeService('tenant_seminar_settings', relatedObjects);

service.beforeCreate = function (payload) {
  delete payload.bookingMailContacts;
  return payload;
};
service.beforeUpdate = function (payload) {
  delete payload.bookingMailContacts;
  return payload;
};

export default service;