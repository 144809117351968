import {makeService, modifyGroups} from './api';

const service = makeService('backend_users');

service.beforeCreate = function (payload) {
  return modifyGroups(payload);
};
service.beforeUpdate = function (payload) {
  return modifyGroups(payload);
};

export default service;
