export default {
  path: '/backend_groups',
  name: 'backend_groups',
  component: () => import('../components/backendgroup/Layout'),
  redirect: { name: 'BackendGroupList' },
  children: [
    {
      name: 'BackendGroupList',
      path: '',
      component: () => import('../views/backendgroup/List'),
      meta: {
        listPage: true
      }
    },
    {
      name: 'BackendGroupCreate',
      path: 'new',
      component: () => import('../views/backendgroup/Create'),
      meta: {
        createPage: true
      }
    },
    {
      name: 'BackendGroupUpdate',
      path: ':id/edit',
      component: () => import('../views/backendgroup/Update'),
      meta: {
        updatePage: true
      }
    }
  ],
  meta: {
    admin: true
  }
};
