import { ENTRYPOINT } from '../config/entrypoint';

export const userService = {
  login,
};

function login (email, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password })
  };

  return fetch(`${ENTRYPOINT}/authentication_token`, requestOptions)
    .then(handleResponse)
    .then(tokenData => {
      // login successful if there's a jwt token in the response
      if (tokenData.data.backendAccess === false) {
        return null;
      }
      return tokenData.token;
    });
}

function handleResponse (response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401 && data.message !== 'Invalid credentials.') {
        // auto logout if 401 response returned from api
        // Todo: logout etc.
        location.reload(true);
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}