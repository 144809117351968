export default {
  path: '/menus',
  name: 'menus',
  component: () => import('../components/menu/Layout'),
  redirect: { name: 'MenuList' },
  children: [
    {
      name: 'MenuList',
      path: '',
      component: () => import('../views/menu/List'),
      meta: {
        listPage: true
      }
    },
    {
      name: 'MenuCreate',
      path: 'new',
      component: () => import('../views/menu/Create'),
      meta: {
        createPage: true
      }
    },
    {
      name: 'MenuUpdate',
      path: ':id/edit',
      component: () => import('../views/menu/Update'),
      meta: {
        updatePage: true
      }
    }
  ],
  meta: {
    admin: true
  }
};
