import Vue from 'vue';
import Vuelidate from 'vuelidate';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import VuetifyConfirm from 'vuetify-confirm'

import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'

import { createVuetifyProTipTap } from 'vuetify-pro-tiptap'

import store from './store';
import router from './router';
import i18n from './i18n';
import AuthenticationMixin from "@/mixins/AuthenticationMixin";

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(VuetifyConfirm, {vuetify});
Vue.mixin(AuthenticationMixin)

Vue.use(TiptapVuetifyPlugin, {
  vuetify, // same as "vuetify: vuetify"
  iconsGroup: 'md'
})

const VuetifyProTipTap = createVuetifyProTipTap({
  vuetify,
  lang: 'de'
})
Vue.use(VuetifyProTipTap)


new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
