import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messagesEn from './locales/en';
import messagesDe from './locales/de';

Vue.use(VueI18n);
export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'de',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
  messages: {
    en: messagesEn,
    de: messagesDe
  }
});