import moment from 'moment';

const formatDateTime = function (date, format = 'L') {

  if (!date) {
    return null;
  }
  return moment.parseZone(date).locale(process.env.VUE_APP_I18N_LOCALE).format(format)
};

const getDateTime = function (date, time = '00:00') {
  return moment(date + ' ' + time).locale(process.env.VUE_APP_I18N_LOCALE).format();
}
const getDateObject = function (dateTime) {
  return moment.parseZone(dateTime).locale(process.env.VUE_APP_I18N_LOCALE).toDate();
}

const diffDateTime = function (startDateTime, endDateTime) {
  let diff = getDateObject(endDateTime) - getDateObject(startDateTime);
  return moment.duration(diff);
}

export {formatDateTime, getDateTime, getDateObject, diffDateTime}
