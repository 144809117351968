export default {
    path: '/newsletterSubscriptions',
    name: 'newsletterSubscriptions',
    component: () => import('../components/newsletterSubscription/Layout'),
    redirect: { name: 'NewsletterSubscriptionList' },
    children: [
      {
        name: 'NewsletterSubscriptionList',
        path: '',
        component: () => import('../views/newsletterSubscription/List'),
        meta: {
          listNewsletterSubscription: true
        }
      },
    ],
    meta: {
      admin: true
    }
  };
  