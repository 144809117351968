import AccountConfirm from '../views/pages/AccountConfirm';

export default {
  name: 'Start',
  path: '/',
  redirect: {name: 'Dashboard'},
  component: () => import('../components/pages/Layout'),
  children: [
    {
      name: 'Dashboard',
      path: '',
      component: () => import('../views/pages/Dashboard'),
      meta: {
        admin: true,
        noAccessToLogin: true
      }
    },
    {
      name: 'login',
      path: '/login',
      component: () => import('../views/pages/Login'),
      meta: {
        guest: true
      }
    },
    {
      name: 'NoAccess',
      path: 'no-access',
      component: () => import('../views/pages/NoAccess'),
      meta: {
        guest: true
      }
    },
    {
      name: 'SpeakerUserConfirm',
      path: 'speaker-confirm/:token',
      component: AccountConfirm,
      meta: {
        guest: true,
        type: 'speaker',
      }
    },
    {
      name: 'AdminUserConfirm',
      path: 'admin-confirm/:token',
      component: AccountConfirm,
      meta: {
        guest: true,
        type: 'admin',
      }
    },
  ],
  meta: {
    guest: false
  }
};
