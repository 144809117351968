export default {
  path: '/tenant_groups',
  name: 'tenant_groups',
  component: () => import('../components/tenantGroup/Layout'),
  redirect: {name: 'TenantGroupList'},
  children: [
    {
      name: 'TenantGroupList',
      path: '',
      component: () => import('../views/tenantGroup/List'),
      meta: {
        listPage: true
      }
    },
    {
      name: 'TenantGroupCreate',
      path: 'new',
      component: () => import('../views/tenantGroup/Create'),
      meta: {
        createPage: true
      }
    },
    {
      name: 'TenantGroupUpdate',
      path: ':id/edit',
      component: () => import('../views/tenantGroup/Update'),
      meta: {
        updatePage: true
      }
    }
  ],
  meta: {
    admin: true
  }
};
