import {makeService} from './api';

const relatedObjects = {
    seminars: {
      byId: {},
      totalItems: 0
    },
}

const service = makeService('seminar_packages', relatedObjects)

export default service;