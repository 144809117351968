import {makeService} from './api';


const relatedObjects = {
  tenant_users: {
    byId: {},
    totalItems: 0
  },
}

const service = makeService('tenants', relatedObjects);

export default service;