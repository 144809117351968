export default {
  path: '/speakers',
  name: 'speakers',
  component: () => import('../components/speaker/Layout'),
  redirect: { name: 'SpeakerList' },
  children: [
    {
      name: 'SpeakerList',
      path: '/list',
      component: () => import('../views/speaker/List'),
      meta: {
        listPage: true
      }
    },
    {
      name: 'SpeakerCalendar',
      path: 'calendar',
      component: () => import('../views/speaker/Calendar'),
    },
    {
      name: 'SpeakerCreate',
      path: 'new',
      component: () => import('../views/speaker/Create'),
      meta: {
        createPage: true
      }
    },
    {
      name: 'SpeakerUpdate',
      path: ':id/edit',
      component: () => import('../views/speaker/Update'),
      meta: {
        updatePage: true
      }
    },
    {
      name: 'SpeakerShow',
      path: ':id',
      component: () => import('../views/speaker/Show'),
      meta: {
        detailPage: true
      }
    }
  ],
  meta: {
    admin: true
  }
};
