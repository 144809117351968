import {makeService} from './api';

const relatedObjects = {
  modules: {
    byId: {},
    totalItems: 0
  },
  expenses: {
    byId: {},
    totalItems: 0
  },
  notes: {
    byId: {},
    totalItems: 0
  },
}
const service = makeService('seminars', relatedObjects)

export default service;
